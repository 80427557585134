.analyticsPage {
    .contentContainer {
        overflow-y: auto;
        padding: var(--medium-gap);
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: var(--big-gap);

        .yearAndMonthPickerContainer {
            display: flex;
            gap: var(--big-gap);
        }

        .tableContainer {
            flex-shrink: 0;
            max-width: 100%;
            overflow-x: auto;
            padding-bottom: var(--medium-gap);
        }

    }
}
