.patientFormDialog {
    width: 90vw;
    height: 90vh;

    &[open] {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: var(--big-gap);
    }

    .patientForm {
        flex-shrink: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        padding-top: calc(var(--big-gap) + var(--big-gap));
        max-width: var(--max-content-width);
        
        .infoContainer {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-auto-flow: row;
            grid-auto-rows: 1fr;
            gap: var(--medium-gap);
            padding-bottom: var(--big-gap);

            .labelAndInputContainer {
                display: grid;
                grid-template-columns: 100px 1fr;
                grid-template-rows: min-content;
                /* Make sure it propagates (align-items: center) */
                align-items: center;
            }
        }
    
        .buttonsContainer {
            display: flex!important;
            justify-content: space-between;
            grid-column: 1 / 4;
        }
    }

    .appointmentsContainer {
        width: 100%;

        .appointmentsLine {
            font-style: italic;
            align-self: start;
            font-weight: 700;
        }
    
        .appointmentsListAndHeaderContainer {
            flex-shrink: 1;
            border: var(--border);
            width: min(100%, var(--max-content-width));
            overflow-y: auto;
    
            .headerRowContainer {
                display: grid;
                grid-auto-flow: column;
                grid-auto-columns: 1fr;
                border-bottom: var(--border);
    
                & p:first-of-type {
                    padding-left: var(--medium-gap);
                }
                
                & p:last-of-type {
                    padding-right: var(--medium-gap);
                }
            }
    
            .appointmentListContainer {
                
                .noAppointmentsFoundLine {
                    padding-left: var(--medium-gap);
                }
            }
        }
    
        .appointmentFormDialog {
            width: 75vw;
            height: 75vh;
    
            .infoContainer {
                align-self: center;
            }
        }
    }
}
