.appointmentListItem {
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
    grid-template-rows: min-content;
    user-select: none;

    &:hover, &.emptyTimeSlot:hover {
        background-color: var(--primary-container);
    }
    
    &:hover p, &.emptyTimeSlot:hover p {
        color: var(--on-primary-container);
    }
    
    &.noshow {
        background-color: var(--warning-secondary);
    }
    
    &.emptyTimeSlot {
        background-color: var(--surface);
    }

    .infoContainer {
        /* overflow: scroll; */

        &:first-of-type {
            padding-left: var(--medium-gap);
        }
        
        &:last-of-type {
            padding-right: var(--medium-gap);
        }
    }
}

.noAppointmentsFoundLine {
    display: none;
}