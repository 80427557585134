.dialog.loadingScreenDialog {
        
    &[open] {
        display: none;
        border: none;
        outline: none;
        background-color: rgba(255, 0, 0, 0);
        width: 100vw;
        height: 100vh;
        padding: 0;
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}