header.header {
    height: var(--header-height);
    padding: 0 var(--side-padding-for-sections);
    background-color: var(--surface);
    display: flex;
    align-items: center;
    border-bottom: var(--border);
    user-select: none;

    .menuContainer {
        display: flex;
        height: 100%;
        width: 100%;

        .menuItem {
            list-style: none;
            height: 100%;
            
            .menuLink {
                display: block;
                height: 100%;
                display: flex;
                align-items: center;
                text-decoration: none;
                color: var(--on-background);
                border-left: 1px solid rgba(255, 255, 255, 0);
                border-right: 1px solid rgba(255, 255, 255, 0);
                transition: var(--transition);
                padding: 0 var(--medium-gap);
                font-size: 24px;

                &.active {
                    background-color: var(--surface-variant);
                    border-left: var(--border);
                    border-right: var(--border);
                    font-weight: 700;
                    text-decoration: underline;
                }
            }

            &:last-of-type {
                margin-left: auto;
            }
        }
    }
}

