.appointmentsPage {

    .contentContainer {
        height: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 100px 50px 1fr;
        
        .header {
            grid-column: 1 / 4;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;

            .dateContainer {
                grid-column: 1 / 2;
                grid-row: 1 / 2;
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                align-items: center;
    
                .todayContainer, .tomorrowContainer, .datePickerContainer {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    text-align: center; 
                    height: 100%;
                    transition: var(--transition);
                    padding: 0 var(--medium-gap);
                    user-select: none;

                    &.active {
                        background-color: var(--surface-variant);
                        border-left: var(--border);
                        border-right: var(--border);
                    }

                    &.active.todayContainer {
                        border-left: none;
                    }
                    
                    .fullDate {
                        flex-basis: 0px;
                        overflow: hidden;
                        opacity: 0;
                        transition: var(--transition);
                    }
                    
                    .fullDate.active {
                        opacity: 1;
                        flex-basis: 55px;
                    }
                }
    
                .datePickerContainer {
                    position: relative;
                    /* height: 100%; */
    
                    .datePickerLabel {
                        /* height: 100%; */
                        display: flex;
                        align-items: center;
                        text-align: center;
                    }
    
                    /* The input=date is hidden in this fashion, because the date picker is called by clicking the label. If the input=date element is display=none, then the picker appears on the top left on the screen. */
                    .datePicker.inputField {
                        visibility: hidden;
                        position: absolute;
                        top: 50%;
                        left: -50%;
                        transform: scale(0.001);
                        pointer-events: none;
                    }
                }
            }
            
            .addNewContainer {
                grid-column: 2 / 3;
                grid-row: 1 / 2;
                display: flex;
                align-items: center;
                justify-content: center;
                user-select: none;
            }
            
            .searchContainer {
                grid-column: 3 / 4;
                grid-row: 1 / 2;
                display: flex;
                align-items: center;
                justify-content: end;
                padding-left: var(--medium-gap);
                padding-right: var(--medium-gap);
                gap: var(--medium-gap);
                transition: var(--transition);

                &.active {
                    background-color: var(--surface-variant);
                    border-left: var(--border);
                }

                .searchLabel {
                    display: flex;
                    align-items: center;
                }
            }
        }

        .headerRowContainer {
            grid-column: 1 / 4;
            grid-row: 2 / 3;
            display: grid;
            grid-auto-columns: 1fr;
            grid-auto-flow: column;
            border-top: var(--border);
            border-bottom: var(--border);
            transition: var(--transition);

            &.active {
                background-color: var(--surface-variant);
            }
            
            & p {
                display: flex;
                align-items: center;
            }

            & p:first-of-type {
                padding-left: var(--medium-gap);
            }
            
            & p:last-of-type {
                padding-right: var(--medium-gap);
            }
        }
        
        .appointmentListContainer {
            grid-column: 1 / 4;
            grid-row: 3 / 4;
            overflow-y: auto;
            transition: var(--transition);

            &.active {
                background-color: var(--surface-variant);
            }
        }
    }

    .searchLabel {
        display: flex;
        align-items: center;
    }

}
