.loginPage {

    &.section {
        height: 100vh;
        margin: 0;
        background-color: var(--background);
        border: none;
    }

    .loginFormDialog {
        width: 600px;
        height: 600px;

        .form {
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: var(--big-gap);

            .serverMessage.fail {
                color: red;
            }

            .serverMessage.success {
                color: green;
            }

            .buttonsContainer {
                display: flex;
                flex-direction: column;
                gap: var(--medium-gap);
            }
    
            .registerLine {
                text-decoration: underline;
            }
        }
    }
}