/* @font-face {
    font-family: 'PlayfairDisplaySC';
    src: 
        local('PlayfairDisplaySC'),
        url('../fonts/PlayfairDisplaySC-Regular.ttf') format('truetype');
} */
    
@font-face {
    font-family: 'Jost';
    src: 
        local('Jost'),
        url('../fonts/Jost-Regular.ttf') format('truetype');
}

:root {
    --header-height: 10vh;
    --big-gap: 32px;
    --medium-gap: 16px;
    --small-gap: 8px;
    --max-content-width: 1200px;
    --side-padding-for-sections: max(7vw, calc((100vw - var(--max-content-width)) / 2));
    --normal-text-size: 16px;
    --h1-text-size: 42px;
    --h2-text-size: 34px;
    --h3-text-size: 24px;


    --primary: #f56f6f;
    --on-primary: #ffffff;
    --primary-container: #3160ed;
    --on-primary-container: #ffffff;

    --secondary: #64bcff;


    --background: #ffffff;
    --on-background: #1f1a1c;

    --surface: #f0f3ff;
    --on-surface: #1f1a1c;
    --surface-variant: #bfd1ff;
    --on-surface-variant: #504348;

    --inactive: lightgrey;
    --on-inactive: black;
    
    --outline: #827378;

    --transparent: rgba(255, 255, 255, 0);

    --close-button: red;
    --warning-secondary: rgb(254, 128, 128);

    --border: 1px solid var(--outline);
    --border-radius: 0px;

    --font-family-1: 'Jost';
    --font-family-2: 'Jost';

    --transition: all .5s ease-in-out;
    --transition-slow: all 1.5s ease-in-out;
    --transition-fast: all .25s ease-in-out;

    --shadow: 5px 5px 5px 5px black;
}