.patientListItem {
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
    grid-template-rows: min-content;
    gap: var(--small-gap);
    user-select: none;
    padding-left: var(--medium-gap);
    padding-right: var(--medium-gap);

    &:hover {
        background-color: var(--primary-container);
    }
    
    &:hover p {
        color: var(--on-primary-container);
    }
}