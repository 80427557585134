.appointmentFormDialog {
    width: 90vw;
    height: 90vh;

    .appointmentForm {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        height: 100%;
        overflow-y: auto;
        padding-top: calc(var(--big-gap) * 2);

        &.inPatientDialogForm {
            
            .inputField:focus, input:focus {
                background-color: var(--surface);
                color: var(--on-surface);
                border: 1px solid var(--surface);
                border-bottom: var(--border);
            }
        }
        
        .infoContainer {
            align-self: end;
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-auto-flow: row;
            grid-auto-rows: 1fr;
            gap: var(--medium-gap);
            padding-bottom: var(--big-gap);
    
            .labelAndInputContainer {
                display: grid;
                grid-template-columns: 100px 1fr;
                grid-template-rows: min-content;
                align-items: center;

                .timeHiddenInputField {
                    display: none;
                }
            }

            .commentsContainer {
                justify-self: center;
                max-width: 500px;
                grid-column: 1 / 3;

                .commentsTextarea {
                    resize: none;
                }
            }
        }
    
        .buttonsContainer {
            width: 100%;
            display: flex;
            gap: var(--medium-gap);
            align-self: end;

            .addAnotherAppointment, .addNewAppointment {
                margin-left: auto;
            }


        }
    }
}
