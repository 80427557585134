.patientsPage {

    .contentContainer {
        height: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 100px 50px 1fr;
        
        .header {
            grid-column: 1 / 4;
            display: flex;
            justify-content: space-between;
            user-select: none;

            .addNewContainer {
                display: flex;
                align-items: center;
                padding-left: var(--medium-gap);
                padding-right: var(--medium-gap);
                transition: var(--transition);

                &.active {
                    background-color: var(--surface-variant);
                    border-right: var(--border);
                }
            }
            
            .searchContainer {
                display: flex;
                align-items: center;
                justify-content: end;
                padding-left: var(--medium-gap);
                padding-right: var(--medium-gap);
                gap: var(--medium-gap);
                transition: var(--transition);

                &.active {
                    background-color: var(--surface-variant);
                    border-left: var(--border);
                }

                .searchLabel {
                    display: flex;
                    align-items: center;
                }
            }
        }

        .headerRowContainer {
            grid-column: 1 / 4;
            grid-row: 2 / 3;
            display: grid;
            grid-auto-columns: 1fr;
            grid-auto-flow: column;
            gap: var(--small-gap);
            border-top: var(--border);
            border-bottom: var(--border);
            padding: 0 var(--medium-gap);
            transition: var(--transition);

            &.active {
                background-color: var(--surface-variant);
            }
            
            & p {
                display: flex;
                align-items: center;
            } 
        }
        
        .patientListContainer {
            grid-column: 1 / 4;
            grid-row: 3 / 4;
            overflow-y: auto;
            padding-bottom: var(--medium-gap);
            transition: var(--transition);

            &.active {
                background-color: var(--surface-variant);
            }
        }
    }
}