*, *::after, *::before {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

h1, h2, p, span, a, div {
    color: var(--on-background);
    line-height: 1.5;
}

h1 {
    font-size: var(--h1-text-size);
    text-align: center;
    font-family: var(--font-family-1);
    padding-bottom: var(--big-gap);
}

h2 {
    font-size: var(--h2-text-size);
    text-align: center;
    padding-top: var(--big-gap);
    padding-bottom: var(--big-gap);
    font-family: var(--font-family-1);
}

h3 {
    font-size: var(--h3-text-size);
    font-family: var(--font-family-1);
}

p, a, span, div {
    font-size: var(--normal-text-size);
    font-family: var(--font-family-2);
}

.section {
    height: calc(100vh - var(--header-height) - var(--big-gap) * 2);
    margin: var(--big-gap) var(--side-padding-for-sections) var(--big-gap) var(--side-padding-for-sections);
    background-color: var(--surface);
    border: var(--border);
}

input, textarea, select {
    &[type=text], &[type=tel], &[type=email], &[type=date], &[type=time], & {
        padding: var(--small-gap);
        font-size: var(--normal-text-size);
        font-family: var(--font-family-2);
        background-color: var(--surface);
        color: var(--on-surface);
        border: 1px solid var(--surface);
        border-bottom: var(--border);
        outline: none;
        transition: var(--transition-fast);
    }
    &:focus {
        background-color: white;
        color: black;
        border: var(--border);
    }
    &[disabled] {
        color: black;
        cursor: not-allowed;
    }
    &[type=password] {
        font: small-caption;
        font-size: var(--normal-text-size);
    }
}

/* Button */

.button {
    height: 50px;
    padding: var(--small-gap);
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: var(--small-gap);
    background: none;
    background-color: var(--primary-container);
    color: var(--on-primary-container);

    border: none;
    cursor: pointer;

    font-family: var(--font-family-2);
    font-size: var(--normal-text-size);
    text-transform: uppercase;

    border: var(--border);

    transition: var(--transition);

    &.secondary {
        background-color: var(--transparent);
        color: var(--on-surface);

        &:hover {
            background-color: var(--primary-container);
            color: var(--on-primary-container);
        }
    }

    &.disabled {
        background-color: var(--inactive);
        color: var(--on-inactive);
        cursor: not-allowed;
        /* pointer-events: none; */
    }
    
    .buttonLink {
        text-decoration: none;
        font-weight: 500;
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1;
    }
}

/* DIALOG */

.dialog {
    &[open] {
        border: var(--border);
        background-color: var(--surface);
        padding: var(--medium-gap);
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
        position: relative;
    }
    .dialogHeader {
        position: absolute;
        top: var(--medium-gap);
        left: var(--medium-gap);
        font-style: italic;
        letter-spacing: 1px;
    }
    .formForDialogCloseButton {
        position: absolute;
        top: var(--medium-gap);
        right: var(--medium-gap);
        display: flex;

        .closeButton {
            border: var(--border);
            border-radius: var(--border-radius);
            background-color: white;
            width: var(--big-gap);
            height: var(--big-gap);
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    &::backdrop {
        animation-name: dialogBackdropAnimation;
        animation-duration: 0.2s;
        animation-fill-mode: forwards;
    }
}

@keyframes dialogBackdropAnimation {
    0% {
        background-color: rgba(255, 255, 255, 0);
    }
    100% {
        background-color: rgba(41, 41, 41, 0.587);
    }
} 

body:has(.dialog[open]) {
    overflow: none;
}

/* TABLE */

table {

    border: var(--border);
    border-collapse: collapse;
    font-size: var(--normal-text-size);
    background-color: var(--surface);
    font-size: 14px;

    & th, & td {
        border: var(--border);
        border-collapse: collapse;
        padding: var(--small-gap);
        min-width: 87px;
    }

    .footerRow, .headerRow, .categoryCell, .totalCell {
        background-color: var(--surface-variant);
        font-weight: 700;
    }

    .totalCell {
        min-width: 100px;
    }
}